import React from 'react'
import styled from 'styled-components'
import media from 'styles/media'
import { PageProps, graphql } from 'gatsby'
import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'
import { Contact } from 'components/organisms/Contact'
import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import { Container } from 'components/atoms/Container'
import { H1Light } from 'components/atoms/Typography'
import MapContainer from 'components/atoms/MapContainer'
import { Wrapper } from 'components/modules/Breadcrumbs/Breadcrumbs.styles'
import { FlatsOnSale } from 'components/organisms/FlatsOnSale'
import { AboutSale } from 'components/organisms/AboutSale'
import { OtherSales } from 'components/organisms/OtherSales'
import { SalesHero } from 'components/organisms/SalesHero'
import { Popup } from 'components/organisms/Popup'

import useBreakpoint from 'hooks/useBreakpoint'

const BreadcrumbsWraper = styled(Container)`
  max-width: calc(1136px + 80px);

  & ${Wrapper} {
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colors.gray05};
  }

  ${media.sm.max} {
    padding: 0px;
  }
`

const HeadingContainer = styled(Container)`
  ${media.sm.max} {
    margin-top: 68px;
  }
`

const promocje: React.FC<PageProps<Queries.SaleSingleQuery>> = ({ data }) => {
  const PAGE = data.wpPromotionsSingle?.promotionSingle
  const PAGE_SEO = data.wpPromotionsSingle?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const OTHER_PAGES = data.allWpPromotionsSingle.nodes

  const HeroData = {
    heading: PAGE.promotionSingleHeroTitle!,
    contentHeading: PAGE.promotionSingleHeroTile?.promotionSingleHeroTileTitle!,
    contentText:
      PAGE.promotionSingleHeroTile?.promotionSingleHeroTileDescription!,
    image: {
      src: PAGE.promotionSingleHeroImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: PAGE.promotionSingleHeroImg?.altText!,
    }!,
  }

  const AboutSaleData = {
    heading: PAGE.promotionSinglePostTitle!,
    content: PAGE.promotionSinglePostDescription!,
    image: {
      src: PAGE.promotionSinglePostImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: PAGE.promotionSinglePostImg?.altText!,
    }!,
  }

  const OtherSalesData = {
    heading: 'Pozostałe promocje',
    tiles: OTHER_PAGES.map((item) => ({
      saleTitle: item.promotionSingle?.promotionSingleHeroTitle!,
      saleRedirect: `/oferty-specjalne/${item.slug}`,
      image: {
        src: item.promotionSingle?.promotionSingleHeroImg?.localFile
          ?.childImageSharp?.gatsbyImageData!,
        alt: item.promotionSingle?.promotionSingleHeroImg?.altText!,
      }!,
    }))!,
  }

  const ContactData = {
    heading: PAGE.promotionSingleFormTitle!,
    formTitle: PAGE.promotionSingleFormFormTitle!,
    image: {
      src: PAGE.promotionSingleFormImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: PAGE.promotionSingleFormImg?.altText!,
    }!,
  }

  const FlatsOnSaleData = {
    heading: PAGE.promotionSingleFlatsListTitle!,
    promoFlats:
      PAGE.promotionSingleFlatsListList?.map((flat) => ({
        flatId: flat?.promotionSingleFlatsListListFlatId!,
        flatPrice: flat?.promotionSingleFlatsListFlatPrice!,
      })) || [],
  }

  const { md } = useBreakpoint()

  const PopupData = {
    image: {
      src: PAGE.promotionSinglePopupImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: PAGE.promotionSinglePopupImg?.altText!,
    },
    link: PAGE.promotionSinglePopupUrl!,
    visibilityMobile: PAGE.promotionSinglePopupVisibilityMobile!,
    delay: PAGE.promotionSinglePopupDelay!,
  }

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      {PopupData.image && (md || PopupData.visibilityMobile) && (
        <Popup
          image={PopupData.image}
          link={PopupData.link}
          delay={PopupData.delay}
        />
      )}

      <HeadingContainer padding={[72, 24]} margin={[110, 'auto', 0, 'auto']}>
        <H1Light
          as="h1"
          align="center"
          dangerouslySetInnerHTML={{ __html: data.wpPromotionsSingle.title! }}
        />
      </HeadingContainer>
      <BreadcrumbsWraper margin={[0, 'auto']} padding={[0, 40]}>
        <Breadcrumbs
          crumbs={[
            { name: 'Oferty specjalne', to: '/oferty-specjalne' },
            { name: data.wpPromotionsSingle?.title || 'Oferta' },
          ]}
        />
      </BreadcrumbsWraper>

      <SalesHero
        heading={HeroData.heading}
        contentHeading={HeroData.contentHeading}
        contentText={HeroData.contentText}
        image={HeroData.image}
      />

      {(FlatsOnSaleData.promoFlats.length > 0 ||
        data.wpPromotionsSingle.title === 'Dni otwarte') && (
        <FlatsOnSale
          heading={FlatsOnSaleData.heading}
          promoFlats={FlatsOnSaleData.promoFlats}
          showAllAvailable={data.wpPromotionsSingle.title === 'Dni otwarte'}
        />
      )}

      {AboutSaleData.heading &&
        AboutSaleData.content &&
        AboutSaleData.image && (
          <MapContainer size="big" position={['bottom', 'left']}>
            <AboutSale
              heading={AboutSaleData.heading}
              content={AboutSaleData.content}
              image={AboutSaleData.image}
            />
          </MapContainer>
        )}

      <OtherSales otherSalesContent={OtherSalesData} />
      <Contact
        heading={ContactData.heading}
        formTitle={ContactData.formTitle}
        image={ContactData.image}
      />
    </Layout>
  )
}

export default promocje

export const query = graphql`
  query SaleSingle($slug: String) {
    wpPromotionsSingle(slug: { eq: $slug }) {
      title
      promotionSingle {
        promotionSinglePopupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        promotionSinglePopupUrl
        promotionSinglePopupVisibilityMobile
        promotionSinglePopupDelay
        promotionSingleHeroTitle
        promotionSingleHeroTile {
          promotionSingleHeroTileTitle
          promotionSingleHeroTileDescription
        }
        promotionSingleHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
        }
        promotionSingleFlatsListTitle
        promotionSingleFlatsListList {
          promotionSingleFlatsListListFlatId
          promotionSingleFlatsListFlatPrice
        }
        promotionSinglePostTitle
        promotionSinglePostDescription
        promotionSinglePostImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        promotionSingleFormTitle
        promotionSingleFormFormTitle
        promotionSingleFormImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
      seo {
        ...WpSEO
      }
    }
    allWpPromotionsSingle(filter: { slug: { ne: $slug } }) {
      nodes {
        promotionSingle {
          promotionSingleHeroTitle
          promotionSingleHeroImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
        slug
      }
    }
  }
`
